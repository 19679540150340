.refresh-timer {
  text-transform: uppercase;
  font-family: "GenshinFont";
  font-size: 12px;
  // width: 125px;
  width: 193px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  align-items: center;  
  margin-right: 5px;
  filter: drop-shadow(0 0 3px #000);
  color: rgba(white, 0.5);

  @at-root .flex-skin-active &,
    .flex-skin-docked & {
    color: #e7f6f2;
  }

  &.no-label {
    width: 41px;
  }

  &.has-hours {
    width: 65px;
    > span {
      width: 100%;
      text-align: right;
    }
  }

  .value {
    font-size: 15px;
  }
}
