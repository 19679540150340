.other-calculations-display {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .other-calc-container {
    display: flex;
    justify-content: space-around;

    gap: 40px;
    row-gap: 40px;
    column-gap: 30px;
    flex-wrap: wrap;
    text-align: center;

    margin: 10px;
    width: min(600px, 100% - 15px);
    flex-grow: 1;

    > div {
      width: min(50%, 250px);
      flex-shrink: 0;
      flex-grow: 1;

      /*
      // last ODD element
      &:nth-child(2n + 1):last-child {
        background-color: rgba(red, 0.2);
        // grid-column: 1 / 3;
        // margin-left: auto;
        // margin-right: auto;
      }
      */
    }

    .flex {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .variant-selection-wrapper {
    flex-grow: 1;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }

  td {
    text-align: center;
  }

  .variants-table {
    &.horizontal {
      tbody {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      tr,
      td {
        display: contents;
      }
      a {
        margin: 0;
      }
    }

    td {
      height: 33px;
    }
    a {
      padding: 8px;
    }
  }

  a {
    padding: 8px;
    margin: 2px;
    color: #e7f6f2;
    text-decoration: none;

    &:hover {
      background-color: rgba(black, 0.3);
      box-shadow: inset 0 0 5px 2px rgba(orange, 0.3);
    }

    &.current-selection {
      background-color: rgba(black, 0.3);
      box-shadow: inset 0 0 5px 2px rgba(black, 0.5);
      &:hover {
        box-shadow: inset 0 0 5px 2px rgba(orange, 0.3);
      }
    }
  }
}

.calc-setup-info-wrapper {
  display: flex;
  flex-wrap: wrap;

  table {
    background-color: rgba(black, 0.3);
    padding: 8px;
    margin: 8px;
    height: fit-content;
    width: 30%;
  }
}

.lb-chart-wrapper {
  width: 500px;

  canvas {
    width: 100% !important;
    cursor: pointer;
  }
}

.lb-team-display-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
}
