.stat-icon {
  width: 18px;
  height: 18px;
}

.header-wrapper {
  .empty-stat-icon {
    display: none;
  }
}
.empty-stat-icon {
  width: 18px;
}