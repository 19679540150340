@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagination-wrapper {
  display: flex;
  // justify-content: flex-end;
  justify-content: center;
  position: relative;

  .pagination-spinner-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    .lds-ellipsis {
      top: -15px;
    }
  }

  .pagination {
    margin-top: 10px;
    margin-bottom: 20px;
    // width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.3s;

    @media (max-width: 500px) {
      margin-top: 18px;
    }

    // &.is-loading {
    //   pointer-events: none;
    //   opacity: 0.2;
    // }

    .pagination-details {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 12px;
      opacity: 0.7;
    }

    .pagination-buttons {
      display: flex;
      gap: 5px;

      .button-wrapper {
        &:hover {
          button {
            &:not(:disabled) {
              cursor: pointer;
              background-color: gray;
            }
          }
        }
        &.highlight-button {
          &:hover {
            button {
              background-color: darken(orange, 10);
            }
          }
          .button-label {
            color: orange;
            opacity: 1;
          }
          button {
            color: #2c3333;
            background-color: orange;
            transform: rotate(360deg);
            animation: 0.5s ease 1 spin;
            animation-fill-mode: forwards;
          }
        }
      }

      .button-label {
        position: absolute;
        width: 100%;
        bottom: -15px;
        left: 0;
        font-size: 12px;
        text-align: center;
        user-select: none;
        cursor: pointer;
        opacity: 0.3;
      }

      button {
        color: white;
        border: none;
        background-color: transparent;
        border-radius: 20px;
        min-width: 30px;
        min-height: 30px;
        padding: 5px;
        text-align: center;
        user-select: none;

        &:disabled {
          cursor: default;
          opacity: 0.33;
          .button-label {
            opacity: 0.9;
            cursor: default;
          }
        }
      }
    }
  }
}
