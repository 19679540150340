$character-card-width: 1200px;
$hardcoded-scale: 1;
// $hardcoded-scale: 0.87;

.lock-viewport {
  overflow: hidden !important;
  touch-action: none !important;

  .custom-table-wrapper {
    .scrollbar-container {
      display: contents !important;
      touch-action: none !important;
    }
    .custom-table {
      overflow: hidden !important;
      display: block !important;
      touch-action: none !important;
    }
  }
  .build-preview {
    .card-wrapper {
      position: absolute !important;
      left: 0 !important;
    }
  }
}

.character-card-container {
  &.is-generating {
    .compact-artifact-crit-value,
    .character-showcase-pic-container,
    .compact-artifact-metric-container {
      pointer-events: none !important;
    }
    span.compact-artifact-crit-value.compact-artifact-crit-value {
      box-shadow: none !important;
    }
    .metric-formula {
      display: none !important;
    }
  }

  .config-overlay {
    z-index: 999;
    top: 0;
    left: 0;
    position: absolute;
    text-align: center;
    display: block;

    .drag-arrows {
      position: absolute;
      top: 50px * $hardcoded-scale;
      left: 0;
      transition: opacity 0.2s;
      pointer-events: none !important;
      svg {
        position: absolute;
        width: 20px * $hardcoded-scale;
        height: 20px * $hardcoded-scale;
      }
    }

    .zoom-level-buttons,
    .top-buttons {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      pointer-events: none;

      .single-config-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px * $hardcoded-scale;
        width: 30px * $hardcoded-scale;
        height: 30px * $hardcoded-scale;
        padding: 5px * $hardcoded-scale;
      }

      &.top-buttons {
        top: 10px * $hardcoded-scale;
        left: 311px * $hardcoded-scale;
        > div {
          &:first-child {
            display: flex;
            white-space: nowrap;
            align-items: center;
            gap: 10px;
          }
        }
      }
      &.zoom-level-buttons {
        top: 220px * $hardcoded-scale;
        left: 452px * $hardcoded-scale;
      }
      > div {
        pointer-events: all;
        background-color: rgba(black, 0.6);
        display: flex;
        flex-grow: 0;
        justify-content: flex-end;
        padding: 15px * $hardcoded-scale;
        border-radius: 5px * $hardcoded-scale;
        border: 1px solid rgba(white, 0.4);
        cursor: pointer;

        &:hover {
          background-color: rgba(black, 0.8);
          border: 1px solid rgba(white, 0.8);
        }
      }
    }
  }
  &:not(.disable-input) {
    &.editable {
      .character-left > div {
        cursor: grab;
      }
    }
    .character-left > div {
      cursor: pointer;
    }
  }
  &.disable-input {
    .character-left > div {
      .character-showcase-pic-container {
        pointer-events: none !important;
      }
    }
  }

  font-size: 16px * $hardcoded-scale;
  .gap-5 {
    gap: 5px * $hardcoded-scale;
  }
  .total-roll-list-wrapper .total-roll-list {
    font-size: 14px * $hardcoded-scale;
    .total-roll-rv {
      // width: 80px * $hardcoded-scale;
      display: flex;
      width: auto;
    }
    .roll-list-member {
      padding: 3px * $hardcoded-scale 7px * $hardcoded-scale;
      span :first-child {
        margin-right: 5px * $hardcoded-scale;
        font-size: 12px * $hardcoded-scale;
      }
      .stat-icon {
        width: 18px * $hardcoded-scale;
        height: 18px * $hardcoded-scale;
      }
    }
  }
  .table-stats-display-card {
    height: 270px * $hardcoded-scale;
    .table-stat-row {
      font-size: 16px * $hardcoded-scale;
      .stat-icon {
        width: 20px * $hardcoded-scale;
        height: 20px * $hardcoded-scale;
      }
    }
  }
  .compact-artifact {
    height: 135px * $hardcoded-scale;

    &:nth-child(1) {
      .compact-artifact-main-stat {
        max-width: 42px * $hardcoded-scale !important;
      }
    }
    &:nth-child(2) {
      .compact-artifact-main-stat {
        max-width: 33px * $hardcoded-scale !important;
      }
    }

    &.metric-CV {
      .compact-artifact-metric-container,
      .compact-artifact-icon-container {
        .compact-artifact-crit-value {
          max-width: 45px * $hardcoded-scale;
        }
      }
    }

    .compact-artifact-metric-container,
    .compact-artifact-icon-container {
      width: 100px * $hardcoded-scale;
      height: 135px * $hardcoded-scale;
      margin-right: 10px * $hardcoded-scale;
      .compact-artifact-crit-value {
        font-size: 12px * $hardcoded-scale;
        max-width: 60px * $hardcoded-scale;
      }
      .compact-artifact-main-stat {
        left: -2px * $hardcoded-scale;
        bottom: 1px * $hardcoded-scale;
        max-width: 50px * $hardcoded-scale;
        height: 24px * $hardcoded-scale;
        padding-left: 30px * $hardcoded-scale;
        .stat-icon {
          width: 22px * $hardcoded-scale;
          height: 22px * $hardcoded-scale;
          left: 4px * $hardcoded-scale;
        }
      }
      .compact-artifact-icon.compact-artifact-icon {
        top: -22px * $hardcoded-scale;
        left: -40px * $hardcoded-scale;
        width: 180px * $hardcoded-scale;
        height: 180px * $hardcoded-scale;
      }
    }
    .compact-artifact-subs {
      margin: 15px * $hardcoded-scale;
      margin-left: 0;
      width: 70px * $hardcoded-scale;
      .substat {
        gap: 3px * $hardcoded-scale;
        &.rv-relevant .stat-highlight {
          right: -15px * $hardcoded-scale;
          margin-top: 6px * $hardcoded-scale;
          $triangle-size: 5px * $hardcoded-scale;
        }
      }
      .stat-icon {
        width: 22px * $hardcoded-scale;
        height: 22px * $hardcoded-scale;
        margin-right: 2px * $hardcoded-scale;
      }
    }
  }
}

@mixin shadow03 {
  position: relative;
  $shadow-color: rgba(black, 0.35);
  .column-shadow-gradient-bottom,
  .column-shadow-gradient-top,
  .column-shadow-gradient-left,
  .column-shadow-gradient,
  .column-shadow {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    width: $character-card-width * $hardcoded-scale;
    // height: 100%;
    // height: 455px * $hardcoded-scale;
    height: 485px * $hardcoded-scale;
    background: $shadow-color;
    z-index: 1;
    pointer-events: none;
  }
  .column-shadow-gradient {
    border-top-left-radius: 4px * $hardcoded-scale;
    border-bottom-left-radius: 4px * $hardcoded-scale;
    background: linear-gradient(90deg, transparent 70%, $shadow-color);
  }
  .column-shadow-gradient-top {
    border-top-left-radius: 4px * $hardcoded-scale;
    border-bottom-left-radius: 4px * $hardcoded-scale;
    background: linear-gradient(-10deg, transparent 80%, $shadow-color);
  }
  .column-shadow-gradient-left {
    border-top-left-radius: 4px * $hardcoded-scale;
    border-bottom-left-radius: 4px * $hardcoded-scale;
    background: linear-gradient(
      90deg,
      $shadow-color,
      transparent calc(500px * $hardcoded-scale * 0.3)
    );
  }
  .column-shadow-gradient-bottom {
    border-top-left-radius: 4px * $hardcoded-scale;
    border-bottom-left-radius: 4px * $hardcoded-scale;
    background: linear-gradient(
      30deg,
      $shadow-color,
      transparent 300px * $hardcoded-scale
    );
  }
}

.html-to-image-target {
  padding: 2px;
  width: fit-content;
}

.debug {
  svg,
  img {
    display: none !important;
  }
  * {
    box-shadow: none !important;
    filter: none !important;
    mask-image: none !important;
    background: transparent !important;
    background-image: transparent !important;
  }
  .elemental-bg {
    > div {
      filter: none !important;
    }
  }
  .elemental-bg-wrap .compact-artifact {
    .compact-artifact-bg {
      filter: none !important;
    }
  }
}

.card-preview-modal {
  .modal {
    // width: 900px !important;
    width: 1200px !important;
    img {
      margin: 15px;
      width: calc(100% - 30px);
    }
    .card-explaination {
      text-align: center;
      margin: 20px 0;
      font-size: 18px;
    }
  }
}

.build-preview {
  .toggled-conf-btn {
    background-color: #282e2e !important;
    transition: background-color 0.6s;
    color: orange;
  }
  .toggle-config {
    background-color: #282e2e !important;
    padding: 5px 15px 15px;
    margin-top: 0 !important;
    width: auto;
  }

  $bg-0: #2c3333;
  $default-bg: rgba(0, 0, 0, 0.3);
  $faded-bg-2: darken($bg-0, 5);
  background: linear-gradient(
    0deg,
    $default-bg 0%,
    $faded-bg-2 180px,
    $faded-bg-2 calc(100% - 400px),
    $default-bg 100%
  ) !important;
}

.custom-table-wrapper .custom-table {
  .toggled-conf-btn {
    background-color: #202525;
    transition: background-color 0.6s;
    color: orange;
  }
  .toggle-config {
    background-color: #202525;
    padding: 5px 15px 15px;
    margin-top: 0 !important;
    width: auto;
  }
  tr:nth-child(2n) {
    .toggled-conf-btn {
      background-color: #282e2e;
    }
    .toggle-config {
      background-color: #282e2e;
    }
  }
}

.card-buttons-wrapper {
  width: 100%;

  .card-buttons {
    margin-left: 30px;
    width: max(min(95%, 90vw), 800px);
    left: 0;
    // display: flex;
    // justify-content: space-between;
    // gap: 20px;

    .buttons-row {
      display: flex;
      gap: 5px;
      .lds-ellipsis,
      .dl-button {
        width: 105px;
        &.opacity-5 {
          pointer-events: none;
        }
      }
      .lds-ellipsis {
        height: 36px;
        top: -22px;
        left: 16px;
      }
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: white;
      padding: 10px;
      position: relative;

      &:hover {
        color: orange;
      }
      svg {
        margin-right: 10px;
      }
      &:disabled {
        opacity: 0.5;
        cursor: no-drop;
        &:hover {
          color: red;
        }
      }
      span {
        font-size: 11px;
        opacity: 0.5;
        margin-left: 10px;
        left: 25px;
        position: absolute;
        top: -10px;
        pointer-events: none !important;
      }
    }
    .card-configuration {
      // width: 100%;
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      $conf-width: 900px;
      $label-width: 180px;

      .card-select-wrapper {
        display: grid;
        align-items: center;
        gap: 10px;
        grid-template-columns: 180px 100%;
        .card-select-label {
          width: $label-width;
          white-space: nowrap;
        }
        &.no-calcs {
          opacity: 0.3;
          cursor: not-allowed;
          .card-select:hover {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
      .card-checkboxes {
        width: $conf-width;
        margin-top: 5px;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        flex-direction: column;
        label {
          display: inline-block;
          width: $label-width;
        }
        input {
          margin-left: 15px;
        }
        button {
          margin-left: 5px;
        }
        .disabled {
          opacity: 0.5;
        }
        .disabled,
        .disabled * {
          cursor: not-allowed !important;
          color: white !important;
        }
        .lds-ellipsis {
          height: 12px;
          > div {
            top: 0px;
          }
        }
      }
      .card-select {
        margin-top: 10px;
        background-color: rgba(0, 0, 0, 0.4);
        height: 32px;
        user-select: none;
        display: flex;
        align-items: center;
        width: fit-content;
        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
        .react-select-calcs-wrapper {
          display: flex;
          justify-content: flex-end;
          > div {
            width: $conf-width - $label-width !important;
          }
          .react-select-custom-option {
            .for-pills {
              display: flex;
              gap: 5px;
            }
            .for-dropdown {
              display: none;
            }
          }
        }
      }
    }
  }
}

.react-select-custom-option {
  .for-pills {
    display: none;
  }
  .for-dropdown {
    display: flex;
    gap: 5px;
  }
}

.scale-factor-source {
  $sf: var(--scale-factor);
  $invSf: calc(1 - $sf);

  .card-wrapper-height-fix {
    height: calc($sf * 585px * $hardcoded-scale);
  }

  .card-wrapper {
    margin-top: 10px * $hardcoded-scale;
    // margin-left: 20px * $hardcoded-scale;
    margin-right: 15px * $hardcoded-scale;
    margin-left: 15px * $hardcoded-scale;
    transform: scale($sf)
      translate(
        calc($invSf * -800px * $hardcoded-scale),
        calc($invSf * -380px * $hardcoded-scale)
      );
  }
}

.image-loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 525px * $hardcoded-scale;
  height: 430px * $hardcoded-scale;
  font-family: "GenshinFont";
  text-shadow: 0 0.08em 0.1em black, 0 0.1em 0.3em rgba(0, 0, 0, 0.4);
  .lds-ellipsis {
    transform: scale(1.5);
  }
}

.character-card-container {
  position: relative;
  width: $character-card-width * $hardcoded-scale;
  height: 565px * $hardcoded-scale;
  display: grid;
  grid-template-columns: 500px * $hardcoded-scale 380px * $hardcoded-scale 1fr;

  .wide-bg-shadow {
    position: absolute;
    width: 700px * $hardcoded-scale;
    height: 485px * $hardcoded-scale;
    z-index: 1;
    right: 0;
    background-color: rgba(black, 0.35);
    border-radius: 4px * $hardcoded-scale;
  }

  .teammate-bg {
    filter: drop-shadow(
      0 0 calc(var(--teammate-scale) * 2px * $hardcoded-scale) black
    );
  }

  .overlay-artifact-wrapper,
  .overlay-weapon-wrapper {
    filter: drop-shadow(
      0 0 calc(var(--teammate-scale) * 1px * $hardcoded-scale) black
    );
  }

  &.no-leaderboards {
    grid-template-columns: 500px * $hardcoded-scale 1fr;
    .character-right {
      display: none;
    }
    .character-middle {
      width: 400px * $hardcoded-scale;
      left: 130px * $hardcoded-scale;
    }
  }

  &.simplify-colors {
    // $simplified-color: #5f5f5f;
    $simplified-color: var(--element-color);
    $simplified-color-2: var(--element-color-2);
    // .character-card-background {
    //   box-shadow: 0 0 0 1px * $hardcoded-scale $simplified-color !important;
    // }
    .compact-artifact {
      // box-shadow: 0 0 0 1px * $hardcoded-scale $simplified-color, 0 0 0px * $hardcoded-scale 3px * $hardcoded-scale $simplified-color-2 !important;
      border: 1px * $hardcoded-scale solid $simplified-color !important;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: -3px * $hardcoded-scale;
        left: -3px * $hardcoded-scale;
        width: 100%;
        height: 100%;
        border: 3px * $hardcoded-scale solid $simplified-color-2 !important;
        z-index: 1;
      }
      &::before {
        display: none !important;
      }
      .substat.rv-relevant {
        .stat-highlight.stat-highlight {
          border-right-color: $simplified-color !important;
        }
      }
    }
    .character-artifacts-rv {
      .active-rv {
        // box-shadow: 0 0 0 1px * $hardcoded-scale $simplified-color, 0 0 0px * $hardcoded-scale 3px * $hardcoded-scale $simplified-color-2 !important;
        border: 1px * $hardcoded-scale solid $simplified-color !important;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -3px * $hardcoded-scale;
          left: -3px * $hardcoded-scale;
          width: 100%;
          height: 100%;
          border-radius: 100px * $hardcoded-scale;
          border: 3px * $hardcoded-scale solid $simplified-color-2 !important;
          z-index: 1;
        }
      }
      // .total-roll-list-wrapper
      //   .total-roll-list
      //   .roll-list-member
      //   span
      //   :first-child {
      //   // color: $simplified-color;
      // }
    }
  }

  .absolute-overlay {
    position: absolute;
    margin: 25px * $hardcoded-scale 30px * $hardcoded-scale;
    // width: 100%;
    width: calc($character-card-width - 50px) * $hardcoded-scale;
    height: 350px * $hardcoded-scale;
    z-index: 2;
    font-family: "GenshinFont";
    text-shadow: 0 0.08em 0.1em black, 0 0.1em 0.3em rgba(0, 0, 0, 0.4);
    pointer-events: none;

    .character-name {
      font-size: 22px * $hardcoded-scale;
      display: flex;
      // justify-content: space-between;
      // align-items: flex-start;
      // gap: 30px * $hardcoded-scale;
      position: relative;
      width: 480px * $hardcoded-scale;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 30px * $hardcoded-scale;
      row-gap: 6px * $hardcoded-scale;
      margin-bottom: 6px * $hardcoded-scale;

      > div:not(.character-nickname) {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .character-nickname {
        font-size: 14px * $hardcoded-scale;
        z-index: 2;
      }
    }
    .character-level {
      margin-top: 5px * $hardcoded-scale;
      font-size: 16px * $hardcoded-scale;
      // width: fit-content;
      .opacity-5 {
        margin-left: 2px * $hardcoded-scale;
      }
    }
    .character-friendship {
      margin-top: 5px * $hardcoded-scale;
      font-size: 16px * $hardcoded-scale;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 1px * $hardcoded-scale;
      img {
        position: relative;
        left: -2px * $hardcoded-scale;
        width: 20px * $hardcoded-scale;
        height: 20px * $hardcoded-scale;
        filter: drop-shadow(0 0 0.1em rgba(black, 0.9))
          drop-shadow(0 0.1em 0.3em rgba(0, 0, 0, 0.4));
        // filter: brightness(100) drop-shadow(0 0 0.1em rgba(black, 0.9))
        //   drop-shadow(0 0.1em 0.3em rgba(0, 0, 0, 0.4));
      }
    }
    .character-cv {
      position: absolute;
      margin-top: 5px * $hardcoded-scale;
      width: fit-content;
      bottom: -105px * $hardcoded-scale;
      // right: 38px * $hardcoded-scale;
      right: -13px * $hardcoded-scale;
      font-size: 12px * $hardcoded-scale;
    }
    .character-uid {
      position: absolute;
      // top: 0;
      left: 45px * $hardcoded-scale;
      bottom: -2px * $hardcoded-scale;
      font-size: 14px * $hardcoded-scale;
      z-index: 3;
    }
    .character-constellations {
      // margin-top: 15px * $hardcoded-scale;
      display: flex;
      flex-direction: column;
      gap: 13.5px * $hardcoded-scale;
      position: absolute;
      bottom: -90px * $hardcoded-scale;
      left: -10px * $hardcoded-scale;
      $const-default-bg: #1d1d1dc9;
      $const-square-size: 33px * $hardcoded-scale;
      > div {
        width: $const-square-size;
        height: $const-square-size;
        position: relative;

        .no-const,
        .const-locked {
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($const-default-bg, 0.7);
          width: $const-square-size;
          height: $const-square-size;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .lock-icon {
            // transform: scale(0.75);
            height: 12px * $hardcoded-scale;
            width: 10.5px * $hardcoded-scale;
          }
        }

        border-radius: 100%;

        &:not(.activated) {
          border: 1px * $hardcoded-scale solid var(--element-color);
        }

        &.activated {
          border: 1px * $hardcoded-scale solid rgba(white, 0.6);
          &::after {
            content: "";
            position: absolute;
            border-radius: 100%;
            top: -2px * $hardcoded-scale;
            left: -2px * $hardcoded-scale;
            width: 100%;
            height: 100%;
            border: 2px * $hardcoded-scale solid var(--element-color);
            z-index: -1;
          }
          &::before {
            content: "";
            position: absolute;
            border-radius: 100%;
            top: -4px * $hardcoded-scale;
            left: -4px * $hardcoded-scale;
            width: 100%;
            height: 100%;
            border: 4px * $hardcoded-scale solid var(--element-color-2);
            z-index: -1;
          }
        }
      }
      img {
        border-radius: 100%;
        background: $const-default-bg;
        width: $const-square-size;
        height: $const-square-size;
        // &.activated {
        //   // ...
        //   // box-shadow: 0 0 0px * $hardcoded-scale 1px * $hardcoded-scale rgba(white, 0.6),
        //   //   0 0 0px * $hardcoded-scale 2px * $hardcoded-scale var(--element-color), 0 0 0px * $hardcoded-scale 4px * $hardcoded-scale var(--element-color-2);

        //   // top: -1px * $hardcoded-scale;
        //   // left: -1px * $hardcoded-scale;
        //   // position: relative;
        //   // z-index: -1;
        // }
        // &:not(.activated) {
        //   // opacity: 0.45;
        //   // box-shadow: 0 0 0px * $hardcoded-scale 1px * $hardcoded-scale var(--element-color);
        //   // border: 1px * $hardcoded-scale solid var(--element-color);
        //   // top: -1px * $hardcoded-scale;
        //   // left: -1px * $hardcoded-scale;
        //   // position: relative;
        //   // z-index: -1;
        // }
      }
    }
    .character-talents {
      position: absolute;
      bottom: 0;
      left: 420px * $hardcoded-scale;
      display: flex;
      flex-direction: column;
      gap: 3px * $hardcoded-scale;
      .talent-display {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.talent-boosted {
          .talent-display-value {
            // background-color: #44cded;
            background-color: var(--element-color);
          }
          > span {
            border: 1px * $hardcoded-scale solid rgba(white, 0.6);
            border-radius: 100px * $hardcoded-scale;

            &::after {
              content: "";
              position: absolute;
              border-radius: 100px * $hardcoded-scale;
              top: -2px * $hardcoded-scale;
              left: -2px * $hardcoded-scale;
              width: 100%;
              height: 100%;
              border: 2px * $hardcoded-scale solid var(--element-color);
              z-index: -1;
            }
            &::before {
              content: "";
              position: absolute;
              border-radius: 100px * $hardcoded-scale;
              top: -4px * $hardcoded-scale;
              left: -4px * $hardcoded-scale;
              width: 100%;
              height: 100%;
              border: 4px * $hardcoded-scale solid var(--element-color-2);
              z-index: -1;
            }
          }
          // img:not(.crown-of-insight) {
          //   // box-shadow: 0 0 3px * $hardcoded-scale 1px * $hardcoded-scale rgba(white, 0.4), 0 0 4px * $hardcoded-scale 2px * $hardcoded-scale #44cded;
          //   // box-shadow: 0 0 3px * $hardcoded-scale 1px * $hardcoded-scale rgba(white, 0.4), 0 0 4px * $hardcoded-scale 2px * $hardcoded-scale var(--element-color);
          //   // box-shadow: 0 0 0px * $hardcoded-scale 1px * $hardcoded-scale rgba(white, 0.6),
          //   //   0 0 0px * $hardcoded-scale 2px * $hardcoded-scale var(--element-color),
          //   //   0 0 0px * $hardcoded-scale 4px * $hardcoded-scale var(--element-color-2);
          // }
        }
        &.talent-crowned {
          .talent-display-value {
            color: rgb(255, 185, 56); // orange-ish
          }
        }

        > span {
          height: 39px * $hardcoded-scale;
          position: relative;
          bottom: -5px * $hardcoded-scale;
          border: 1px * $hardcoded-scale solid rgba(white, 0.3);
          border-radius: 100px * $hardcoded-scale;
        }
        .talent-icon-placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }
        .talent-icon-placeholder,
        img:not(.crown-of-insight) {
          background-color: rgba(rgb(34, 34, 34), 0.7);
          border-radius: 100%;
          width: 33px * $hardcoded-scale;
          height: 33px * $hardcoded-scale;
          z-index: 1;
          position: relative;
          padding: 3px * $hardcoded-scale;
          box-shadow: 0 0 3px * $hardcoded-scale 1px * $hardcoded-scale
            rgba(white, 0.25);
        }
        .talent-display-value {
          font-size: 13px * $hardcoded-scale;
          padding: 3px * $hardcoded-scale 6px * $hardcoded-scale;
          background-color: #3a3a3a;
          border-radius: 10px * $hardcoded-scale;
          z-index: 3;
          width: fit-content;
          display: flex;
          gap: 3px * $hardcoded-scale;
        }
        .crown-of-insight {
          width: 15px * $hardcoded-scale;
          height: 15px * $hardcoded-scale;
          z-index: 1;
          filter: drop-shadow(0 0.08em 0.1em rgba(black, 0.5));
        }
      }
    }
  }

  .card-leaderboards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    text-align: center;
    height: 390px * $hardcoded-scale;
    gap: 25px * $hardcoded-scale;
    font-size: 12px * $hardcoded-scale;

    > div {
      // display: flex;
      // white-space: nowrap;
      // align-items: center;
      // gap: 5px * $hardcoded-scale;
      width: 100%;
      margin-left: 10px * $hardcoded-scale;
    }
    .lb-badge {
      font-weight: 600;
      border-radius: 5px * $hardcoded-scale;
      padding: 3px * $hardcoded-scale 8px * $hardcoded-scale;
      font-size: 14px * $hardcoded-scale;
      color: white;
      filter: drop-shadow(0 0 2px * $hardcoded-scale rgba(black, 0.3));
      .weapon-icon {
        position: relative;
        // top: 7px * $hardcoded-scale;
        left: -3px * $hardcoded-scale;
        width: 30px * $hardcoded-scale;
        height: 30px * $hardcoded-scale;
        filter: drop-shadow(0 0 2px * $hardcoded-scale black);
      }
    }
  }

  .character-left {
    @include shadow03();

    // margin-top: 40px * $hardcoded-scale;
    // margin-bottom: 40px * $hardcoded-scale;
    // margin-left: 40px * $hardcoded-scale;
    z-index: 1;

    .character-showcase-pic-container {
      width: 200%;
      overflow: hidden;
      height: 485px * $hardcoded-scale;
      border-radius: 5px * $hardcoded-scale;
      pointer-events: none;
      position: relative;

      canvas {
        position: absolute;
        top: 0;
        left: 0;
      }

      &.is-traveler {
        img {
          top: -40px * $hardcoded-scale;
          left: -7%;
          // mask-image: linear-gradient(
          //   to left,
          //   transparent 0%,
          //   transparent 50px * $hardcoded-scale,
          //   black 70px * $hardcoded-scale
          // );
        }
      }
      &.horizontal {
        img {
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          object-fit: cover;
          // object-position: 10% 0;
          // mask-image: linear-gradient(
          //   to left,
          //   transparent 0%,
          //   transparent 1px * $hardcoded-scale,
          //   black 100px * $hardcoded-scale
          // );
        }
      }
      img {
        height: 750px * $hardcoded-scale;
        left: -50%;
        top: -85px * $hardcoded-scale;
        position: relative;
        z-index: 0;
        //   mask-image: linear-gradient(
        //     to bottom,
        //     transparent 0px * $hardcoded-scale,
        //     transparent 5px * $hardcoded-scale,
        //     $blackish 50px * $hardcoded-scale,
        //     transparent 50px * $hardcoded-scale,
        //   ),
        // ...
        // mask-image: linear-gradient(
        //   to right,
        //   transparent 0px * $hardcoded-scale,
        //   transparent 455px * $hardcoded-scale,
        //   black 550px * $hardcoded-scale,
        //   black 800px * $hardcoded-scale,
        //   transparent 950px * $hardcoded-scale
        // );
      }
    }
    > div {
      height: 485px * $hardcoded-scale;
      $shadow-color: rgba(black, 0.15);
      background: linear-gradient(90deg, $shadow-color 80%, transparent);
      border-radius: 4px * $hardcoded-scale;

      .character-showcase-pic-container {
        &.is-generating {
          &::after,
          &::before {
            display: none !important;
            opacity: 0 !important;
          }
        }
        &:not(.disable-input) {
          &.editable {
            &.is-dragging {
              &::after {
                opacity: 0.33;
              }
            }
            &::after {
              left: 140px * $hardcoded-scale;
              content: "Drag to adjust image";
            }
            &::after,
            &::before {
              opacity: 1;
            }
          }
          &::after {
            transition: opacity 0.2s;
            opacity: 0;
            position: absolute;
            content: "Click to configure image";
            display: flex;
            font-family: "GenshinFont";
            font-size: 20px * $hardcoded-scale;
            text-shadow: 0 0.08em 0.1em black, 0 0.1em 0.3em rgba(0, 0, 0, 0.4);
            top: 185px * $hardcoded-scale;
            left: 120px * $hardcoded-scale;
            pointer-events: none;
            z-index: 2;
          }
          &::before {
            transition: opacity 0.2s;
            opacity: 0;
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 485px * $hardcoded-scale;
            background: linear-gradient(
              90deg,
              rgba(black, 0.3) 40%,
              transparent 50%
            );
            pointer-events: none;
            z-index: 1;
          }
        }
      }
      &:hover {
        .character-showcase-pic-container {
          position: relative;
          &::after,
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
  .character-right {
    z-index: 2;
    @include shadow03();

    // margin-top: 40px * $hardcoded-scale;
    // margin-bottom: 40px * $hardcoded-scale;
    // margin-right: 40px * $hardcoded-scale;
    position: relative;
    overflow: hidden;
    > div {
      display: flex;
      justify-content: center;
    }
  }

  .character-middle-fix {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 385px * $hardcoded-scale;
  }

  .character-middle {
    z-index: 2;
    @include shadow03();
    // margin-top: 40px * $hardcoded-scale;
    // margin-bottom: 40px * $hardcoded-scale;

    .character-stats-inside {
      margin-left: 20px * $hardcoded-scale;
      margin-bottom: auto;
      margin-top: auto;
      .table-stat-row {
        white-space: nowrap;
      }
    }
    .character-weapon {
      margin-left: 20px * $hardcoded-scale;
      font-family: "GenshinFont";
      font-size: 16px * $hardcoded-scale; // used to be 20px
      z-index: 2;
      width: 100%;
      display: flex;
      gap: 10px * $hardcoded-scale;
      min-height: 80px * $hardcoded-scale;
      margin-top: auto;

      .weapon-name {
        text-shadow: 0 0.08em 0.1em black, 0 0.1em 0.3em rgba(0, 0, 0, 0.4);
        max-width: 300px * $hardcoded-scale;
        margin-top: 5px * $hardcoded-scale;
      }
      .weapon-data {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .weapon-stats {
        margin-top: 4px * $hardcoded-scale;
        display: flex;
        font-size: 14px * $hardcoded-scale;
        gap: 10px * $hardcoded-scale;

        > div {
          background-color: rgba(black, 0.4);
          padding: 3px 6px;
          border-radius: 5px;
        }
        &.lighter-color {
          > div {
            background-color: rgba(white, 0.15);
          }
        }
        .weapon-stat-with-icon {
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;
        }

        .opacity-5 {
          margin-left: 2px * $hardcoded-scale;
        }
      }
      .weapon-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 75px * $hardcoded-scale;
          height: 75px * $hardcoded-scale;
          bottom: -6px * $hardcoded-scale;
          position: relative;
        }

        .weapon-rarity {
          position: relative;
          display: flex;
          flex-direction: row;
          bottom: 8px * $hardcoded-scale;

          img {
            width: 13px * $hardcoded-scale;
            height: 13px * $hardcoded-scale;
            filter: drop-shadow(0 0.08em 0.1em rgba(black, 0.5));
          }
        }
      }
    }
  }

  .character-artifacts-rv {
    position: absolute;
    display: flex;
    gap: 20px * $hardcoded-scale;
    grid-column: 1/4;
    justify-content: center;
    margin-bottom: 3px * $hardcoded-scale;
    height: min-content;

    width: 100%;
    bottom: 0;
    z-index: 3;
  }
  .character-artifacts {
    position: absolute;
    display: flex;
    gap: 15px * $hardcoded-scale;
    grid-column: 1/4;
    justify-content: center;
    margin-bottom: 10px * $hardcoded-scale;
    height: min-content;

    width: 100%;
    bottom: 30px * $hardcoded-scale;
    z-index: 2;

    .mobile-fix {
      display: flex;
      gap: 15px * $hardcoded-scale;
      justify-content: center;

      &:not(.metric-formula),
      > *:not(.metric-formula) {
        text-wrap: nowrap;
        white-space: nowrap;
      }

      .mobile-fix {
        justify-content: flex-start;
      }
    }

    .compact-artifact {
      // overflow: hidden;
      position: relative;
      // min-width: 190px * $hardcoded-scale;
      width: 195px * $hardcoded-scale;
      background-color: black;

      // .substat {
      //   span:not(.roll-dots) {
      //     // font-family: "GenshinFont";
      //     // font-size: 15px * $hardcoded-scale;
      //     // line-height: 1.4;
      //     // filter: drop-shadow(0px * $hardcoded-scale 0px * $hardcoded-scale 3px * $hardcoded-scale black);
      //   }
      // }

      // &:first-child {
      //   margin-left: auto;
      // }
      // &:last-child {
      //   margin-right: auto;
      // }

      .compact-artifact-bg {
        position: absolute;
        // content: "";
        height: 100%;
        width: 100%;
        // background-image: var(--elemental-bg);
        // background-image: var(--character-namecard-url);
        background-position-y: bottom;
        background-repeat: repeat;
        // filter: brightness(0.55) blur(15px * $hardcoded-scale);
        opacity: 0.35;
        // background-size: 10000%;
        background-size: initial !important;
      }
      .compact-artifact-bg-canvas {
        position: absolute;
      }
    }
  }

  .chart-radar-wrapper {
    // ...
    // z-index: 5;
    // mask-image: radial-gradient(black 65%, transparent 75%);
    canvas {
      height: 310px * $hardcoded-scale !important;
      width: 310px * $hardcoded-scale !important;
    }
  }

  .under-chart {
    position: relative;
    // top: -18px * $hardcoded-scale;
    top: -32px * $hardcoded-scale;

    > div {
      text-shadow: 0 0.08em 0.1em black, 0 0.1em 0.3em rgba(0, 0, 0, 0.4);
    }

    .opacity-5 {
      margin-left: 2px * $hardcoded-scale;
    }

    .under-chart-badges {
      // justify-content: center;
      // display: flex;
      // height: 25px * $hardcoded-scale;
      margin-top: 15px * $hardcoded-scale;
      display: inline-block;
      width: 100%;

      .mobile-fix {
        // display: flex;
        // justify-content: center;
        height: 25px * $hardcoded-scale;

        &,
        * {
          text-wrap: nowrap;
          white-space: nowrap;
        }

        .mobile-fix {
          // display: flex;
          // justify-content: center;
          width: min-content;
          // margin-left: auto;
          // margin-right: auto;
          position: relative;
          transform: translate(-50%);
          left: 50%;
        }
      }
    }

    .lb-badge {
      background-color: var(--element-color);
      position: relative;
      // display: flex;
      text-wrap: nowrap;
      white-space: nowrap;

      &.with-icon {
        padding-left: 38px * $hardcoded-scale;
        .weapon-icon {
          position: absolute;
          bottom: 0;
          left: 5px * $hardcoded-scale;
        }
      }

      // width: fit-content;
      // width: max-content;
      // width: auto;

      // &:first-child {
      //   margin-left: auto;
      // }
      // &:last-child {
      //   margin-right: auto;
      // }

      > span {
        font-weight: 700;
        color: rgba(white, 0.9);
        text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.6), 0 0 1em rgba(0, 0, 0, 1);
      }
      > img {
        bottom: 8px * $hardcoded-scale;
        position: relative;
      }
    }
    > div {
      margin-top: 5px * $hardcoded-scale;
      font-family: "GenshinFont";
      font-size: 15px * $hardcoded-scale;
    }
  }

  &.elemental-bg-wrap {
    .compact-artifact-bg {
      // filter: contrast(0.75) brightness(1.6) sepia(1)
      //   hue-rotate(var(--hue-rotate)) saturate(3) brightness(0.65) !important; // blur(15px * $hardcoded-scale) !important;
      opacity: 0.66 !important;
      background-size: unset !important;
      background-position-y: center !important;
    }
  }

  .character-card-background {
    //   &::before {
    // content: "";
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 80px * $hardcoded-scale);
    // $gap-x: 40px * $hardcoded-scale;
    // $gap-y: 40px * $hardcoded-scale;
    // top: $gap-x;
    // left: $gap-y;
    // height: calc(100% - $gap-x * 2);
    // width: calc(100% - $gap-y * 2);
    // box-shadow: 0 0 0px * $hardcoded-scale 5px * $hardcoded-scale #1f2323;
    border-radius: 5px * $hardcoded-scale;
    pointer-events: none;
    // box-shadow: 0 0 0 1px * $hardcoded-scale var(--element-color);

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: linear-gradient(
        90deg,
        transparent 336px * $hardcoded-scale,
        rgba(0, 0, 0, 0.35) 480px * $hardcoded-scale,
        rgba(0, 0, 0, 0.35)
      );
      display: block;
    }

    &::after {
      content: "";
      border: 1px * $hardcoded-scale solid var(--element-color);
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      top: -100%;
      z-index: 1;
      box-sizing: border-box;
      border-radius: 5px * $hardcoded-scale;

      // @TODO: check this...
      top: calc(-100% - 4px);
    }

    // filter: drop-shadow(1px * $hardcoded-scale 0px * $hardcoded-scale 0px * $hardcoded-scale var(--element-color))
    //   drop-shadow(-1px * $hardcoded-scale 0px * $hardcoded-scale 0px * $hardcoded-scale var(--element-color))
    //   drop-shadow(0px * $hardcoded-scale 1px * $hardcoded-scale 0px * $hardcoded-scale var(--element-color))
    //   drop-shadow(0px * $hardcoded-scale -1px * $hardcoded-scale 0px * $hardcoded-scale var(--element-color));

    // &.elemental-bg {
    //   > div {
    //     // background-position: top !important;
    //     // filter: contrast(0.75) brightness(1.6) sepia(1)
    //     //   hue-rotate(var(--hue-rotate)) saturate(3);
    //   }
    // }

    > div {
      background-color: var(
        --element-color-2
      ); // this fallback until image is loaded
      // background-image: var(--character-namecard-url);
      background-size: cover;
      background-position-y: bottom;
      background-repeat: repeat;
      border-radius: 5px * $hardcoded-scale;
      width: 100%;
      height: 100%;
    }

    .bg-as-canvas {
      border-radius: 5px * $hardcoded-scale;
      pointer-events: none;
    }
  }
}

// simulate html2canvas which doesnt support filter and box-shadow
.card-wrapper {
  * {
    filter: none !important;
    box-shadow: none !important;
  }
}
