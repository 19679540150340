.damage-distribution-wrapper {
  // width: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;

  .clickable {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;

    &:hover {
      color: orange;
    }
  }

  .side-by-side {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
  }

  .display-formula {
    max-width: 450px;
    line-height: 22px;

    height: max-content;
    height: min-content;
    height: fit-content;
    padding: 10px;
    background: rgba(black, 0.2);

    .final-result {
      font-weight: 600;
      font-size: 20px;
    }

    .formula-part {
      &.highlighted {
        > span:first-child {
          outline: 1px solid var(--color) !important;
          background-color: rgba(black, 0.5);
          border-radius: 2px;
          // background-color: var(--color);
          // color: white !important;
        }
      }

      // > span:first-child {
      //   &:focus,
      //   &:focus-within,
      //   &:hover {
      //     text-decoration: underline;
      //   }
      //   // outline: 1px solid red;
      // }
    }
  }

  .highlighted-damage-source {
    // text-align: center;
    margin-bottom: 10px;
    min-height: 22px;

    table {
      width: 380px;
      max-width: 400px;
      font-size: 14px;

      tr td:first-child {
        background-color: rgba(0, 0, 0, 0.45);
        font-weight: 600 !important;
      }
      td {
        width: 50%;
      }
      th {
        text-align: center;
        border: none !important;
        text-wrap: wrap !important;
        padding: 5px 10px !important;
        font-weight: 600 !important;
      }
    }
  }

  .damage-distribution-container {
    width: 870px;
    background-color: gray;
    height: 30px;
    display: flex;

    border-radius: 5px;
    overflow: hidden;

    border: 1px solid black;

    .damage-data {
      display: inline-block;
      height: 100%;
      // outline: 1px dotted black;
      &:not(:last-child) {
        border-right: 1px solid black;
      }

      &.highlighted {
        .damage-data-content::before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);

          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid black;
        }
      }

      .damage-data-content {
        overflow: hidden;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // cursor: pointer;
        font-weight: 600;
        text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.6), 0 0 1em rgba(0, 0, 0, 1);

        &:focus,
        &:focus-within,
        &:hover {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0.1);
          }
        }
      }
    }
  }
}
