.table-crits-display {
  position: relative;
  white-space: nowrap;
  display: flex;
  gap: 10px;
  // justify-content: space-between;
  > div:first-child {
    width: 73px;
    text-align: end;
  }
  .cv-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(red, 0.5);
  }
}
