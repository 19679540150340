.last-profile-update-raw {
  span:not(:last-child):not(.no-margin) {
    margin-right: 4px;
  }
}

.last-profile-update {
  text-transform: uppercase;
  font-family: "GenshinFont";
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  margin-right: 5px;
  align-items: center;
  height: 21px;
  gap: 5px;
  filter: drop-shadow(0 0 3px #000);
  color: rgba(white, 0.5);

  @at-root .flex-skin-active &,
    .flex-skin-docked & {
    color: #e7f6f2;
  }

  .value {
    font-size: 15px;
  }
}
