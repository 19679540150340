@keyframes languageSlide {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}

.language-switcher {
  position: relative;

  @media (max-width: 1000px) {
    .language-dropdown.language-dropdown {
      top: 0;
      position: relative;
      display: grid;
      box-shadow: none;
      overflow: hidden;
      animation: 0.5s languageSlide ease-in-out forwards;
      > div {
        text-align: center;
        padding: 8px 20px;
        &:not(:last-child)::after {
          width: 80%;
          left: 10%;
        }
      }
    }
  }

  .language-dropdown {
    position: absolute;
    z-index: 999999;
    background-color: #395b64;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
    top: 64px;
    
    > div {
      cursor: pointer;
      padding: 12px 20px;
      position: relative;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 90%;
        border-bottom: 1px dashed rgba(white, 0.25);
      }

      &:hover {
        background-color: #426a74;
      }

      &.current-langauge {
        background-color: #426a74;
        box-shadow: #e7f6f2 2px 0 0 inset !important;
        &:hover {
          background-color: lighten(#426a74, 2) !important;
        }
      }
    }
  }
}
