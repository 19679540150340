// .footer-wrapper {
//   margin-top: auto;
// }

.footer {
  margin-top: 20px;
  background-color: #2c3333;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px -3px 8px, rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .footer-main {
    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center;
    font-family: "GenshinFont";
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: 800px) {
      gap: 30px;
      flex-direction: column;
      padding-bottom: 60px;
      padding-top: 40px;
    }

    > span,
    a {
      color: white;
      transition: opacity 0.3s;
      opacity: 0.5;
      text-decoration: none;
      &:hover {
        opacity: 1;
      }
    }

    .annotation {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      opacity: 0.5;
      position: absolute;
      font-size: 10px;
      top: -12px;
      left: 0px;
    }

    .pw-badge {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      a {
        font-size: 12px;
      }
    }
  }
}
