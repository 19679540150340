.stylized-content-block-wrapper {
  position: absolute;
  // top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0 !important;
  opacity: 0;

  $bg-0: #2c3333;
  $bg-1: #2c3333ee;
  $bg-1-5: #2c3333e5;
  $bg-2: #2c3333dd;
  $bg-3: #2c3333cc;
  $bg-4: #2c3333aa;

  &.gradient-low-height {
    &::before {
      $bg-0-a: rgba($bg-0, 0.925);
      pointer-events: none !important;
      content: "";
      position: absolute;
      background-position-y: top;
      left: 0;
      top: 0;
      bottom: unset;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: linear-gradient(180deg, transparent 10px, $bg-0-a 200px);
    }
    &::after {
      background: linear-gradient(0deg, $bg-0 0px, transparent 200px);
    }
    .stylized-content-block {
      background-position-y: max(-200px, -18vw);
    }
  }
  &.gradient {
    &::before {
      $bg-0-a: rgba($bg-0, 0.925);
      pointer-events: none !important;
      content: "";
      position: absolute;
      background-position-y: top;
      left: 0;
      top: 0;
      bottom: unset;
      height: 100%;
      width: 100%;
      z-index: 1;
      // @TODO: extend the gradient to hide all the repeat stitches?
      // @TODO: extend the gradient to hide all the repeat stitches?
      // @TODO: extend the gradient to hide all the repeat stitches?
      background: linear-gradient(
        180deg,
        transparent 50px,
        $bg-0-a 150px,
        $bg-0 350px,
        $bg-0-a 450px
      );
    }
    &::after {
      background: linear-gradient(0deg, $bg-0 0px, transparent 300px);
    }
    .stylized-content-block {
      background-position-y: max(-200px, -18vw);
    }
  }
  &.gradient-reverse {
    &::after {
      background: linear-gradient(
        180deg,
        $bg-3 5%,
        $bg-4 65%,
        rgba($bg-4, 0.3) 100%
      );
      height: 100%;
    }
    .stylized-content-block {
      background-position-y: -30px;
      height: 1000px;
      @media (max-width: 800px) {
        background-position-y: 0;
      }
    }
  }
  .stylized-content-block {
    position: absolute;
    background-image: var(--name-card-url);
    background-size: contain;
    background-repeat: repeat;
    // @TODO: if i extend the gradient fade-outs then this should be reset a bit
    background-position-y: -24px;
    top: 0;
    // bottom: 0;
    left: 0;
    width: 100%;
    height: 500%; // fixes low height block visuals
    opacity: 1;
    border-radius: 2px;
  }
  &::after {
    pointer-events: none !important;
    content: "";
    position: absolute;
    // @TODO: extend the gradient to hide all the repeat stitches?
    // @TODO: extend the gradient to hide all the repeat stitches?
    // @TODO: extend the gradient to hide all the repeat stitches?
    background: linear-gradient(0deg, $bg-0 0%, $bg-1 300px, $bg-1-5 850px);
    background-position-y: top;
    left: 0;
    top: unset;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

// background changes fixes for some browsers
.stylized-content-block-wrapper {
  z-index: 0;
  &,
  .stylized-content-block {
    pointer-events: none;
  }

  ~ * {
    position: relative;
    // z-index: 2;
  }
}
