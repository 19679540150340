.lookup-not-found-prompt {
  position: relative;
  color: orange;
  text-align: center;

  a {
    color: rgb(255, 60, 0);
    font-weight: 600;
  }
}

.lookup-uid-wrapper {
  > div {
    padding: 10px 0;
  }
}
