.react-select-menu-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 30;
  animation: modalRevealMenu 0.5s ease-in-out forwards;
  pointer-events: none;
  inset: 0;
  > div {
    pointer-events: all;
    // animation: reactSelectReveal 0.2s ease-in-out forwards;
    // background-color: black;
    // font-size: 14px;
  }
}

.react-select-custom-option {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 13px;
  img {
    width: 20px;
    height: 20px;
  }
  svg {
    width: 16px;
    height: 16px;
    margin: 0 2px;
    opacity: 0.9;
  }
}

.filters-container {
  position: relative;
  background-color: rgba(black, 0.4);
  margin-bottom: 10px;
  // padding: 5px;
  height: 32px; // before -> 32px[th height] - (5px*2)[padding]
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;

  .pills-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    // padding: 5px;
    // gap: 7px;
    // width: calc(100% - 40px);
    width: 100%;
    pointer-events: none;
    .custom-query-builder-wrapper {
      pointer-events: all;
      width: 100%;

      .react-select-custom-option {
        img {
          width: 16px;
          height: 16px;
        }
        svg {
          width: 13px;
          height: 13px;
        }
        // > span {
        //   overflow: hidden;
        //   > span {
        //     text-overflow: ellipsis;
        //     overflow: hidden;
        //     display: block;
        //   }
        // }
      }
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    .filter-bg-text {
      opacity: 0.65;
    }
  }
  .filter-bg-text {
    text-align: center;
    pointer-events: none;
    opacity: 0.45;
    font-size: 13px;
    margin-left: 5px;
    margin-bottom: 2px;
  }
  .filter-icon {
    opacity: 0.75;
    // pointer-events: none;
    position: absolute;
    right: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 1;
    }
  }
  .trash-icon {
    width: 10px;
    height: 10px;
    opacity: 0.75;
  }
  .pill {
    pointer-events: all;
    font-size: 13px;
    display: flex;
    white-space: nowrap;
    border-radius: 50px;
    .pill-close,
    .pill-label {
      background-color: #40839b;
    }
    &:hover {
      .pill-close,
      .pill-label {
        background-color: darken(#40839b, 7);
      }
    }
    .pill-label {
      padding: 1px 4px 2px 10px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      color: white;
      &:hover {
        color: orange;
      }
    }
    .pill-close {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      padding: 1px 8px 2px 8px;
      padding-left: 4px;
      &:hover {
        color: red;
      }
    }
  }
}
