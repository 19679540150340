.privacy-policy-content-wrapper {

  h2 {
    margin-top: 30px;
    margin-bottom: 5px;
  }

  h2 + * {
    margin-top: 0px;
  }

  > .vm-container.shrink-ad {
    // video ad
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
  }

  .privacy-policy {
    flex: 1;
    margin-left: 20px;

    @media (max-width: 800px) {
      margin-left: 0px;
    }
    // width: calc(100% - 450px);
    // min-width: min(450px, 100%);
  }
}
