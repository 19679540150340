.calculation-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  .calculation-list {
    font-size: 14px;
    a {
      text-decoration: none;
      color: white;
      &:hover {
        color: orange;
      }
    }
    tr {
      td {
        &:first-child {
          text-align: right;
        }
        &:last-child {
          color: orange;
          font-weight: 600;
          text-align: right;
        }

        white-space: nowrap;
      }
    }

    .max-width-130-ellipsis {
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .pr-0 {
      padding-right: 0 !important; 
    }

    .stacked {
      width: 100%;
      text-align: center;
      height: 22px;
      position: relative;
      display: flex;
      flex-direction: column;
      .stacked-top {
        position: relative;
        top: -4px;
        transform: scale(0.9);
      }
      .stacked-bottom {
        position: relative;
        top: -7px;
        font-size: 10px;
      }
    }
  }
  
  .clickable {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    &:hover {
      color: orange;
    }
  }
}